'use strict';

document.addEventListener('DOMContentLoaded', function() {


  $('.l-navTrigger').on('click', function() {
    $(this).toggleClass('is-open');
    $('.l-nav').toggleClass('is-open');
  });

  $('.l-nav_anchor').on('click', function() {
    $(this).removeClass('is-open');
    $('.l-nav').removeClass('is-open');
  });

  /**
   * パラメータ取得関数
   * @argument name 取得したいパラメータ名
   * @argument url 表示ページ以外のURLから取得したい場合
   */
  function getParam(name, url) {
    if (!url) url = window.location.search;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    let results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    let result = decodeURIComponent(results[2].replace(/\+/g, " "));
    return sanitizeUrl(result);
  }

  
  /**
   * エスケープ関数
   * @argument input エスケープしたい文字列
   */
  function sanitizeUrl(input) {
    if (typeof input !== 'string') {
        throw new TypeError('Expected a string');
    }
    return input.replace(/[&'"<>]/g, function(match) {
        return {
            '&': '&amp;',
            "'": '&#x27;',
            '"': '&quot;',
            '<': '&lt;',
            '>': '&gt;',
        }[match];
    });
  }



  /**
   * target="_blank"がついているaタグにrel="noopener"自動付与する
   * 既にrel="noopener"がついているタグには付与しない。
   */
  (function addNoReferrerToBlankLinks() {
    const links = document.querySelectorAll('a[target="_blank"]');
    for (let i = 0; i < links.length; i++) {
        let rel = links[i].getAttribute('rel');
        if (rel) {
            if (!rel.includes('noopener')) {
                rel += ' noopener';
            }
        } else {
            rel = 'noopener';
        }
        links[i].setAttribute('rel', rel);
    }
  })();


  /**
   * アンカースクロール
   * @variable headerElement 固定ヘッダーセレクタ
   * @variable headerElement スクロール速度
   */
  const headerClass = '.l-header';

  let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 500,
    header: headerClass,
    easing: 'easeInOutQuint'
  });

  // ページ表示時のアンカー位置にヘッダーの高さを考慮する
  const headerElement = document.querySelector(headerClass);
  let headerElementHeight = 0;

  // ロード時にヘッダーが存在していれば高さを取得
  if ( headerElement !== null ) {
    headerElementHeight = headerElement.offsetHeight;
  }
  // ハッシュ値があればロード時に高さを考慮した位置へスクロール位置を差し替え
  if ( window.location.hash !== '' ) {
    window.addEventListener('load', function() {
      const target = document.querySelector(window.location.hash);
      if ( target !== null ) {
        const position = target.offsetTop - headerElementHeight;
        document.documentElement.scrollTop = position;
      }
    });
  }


  /**
   * Javascriptによるメディアクエリ
   * @variable mediaQueryList PC以上の場合
   */
  // const mediaQueryList = window.matchMedia('(min-width: 751px)');
  // handleMediaQuery(mediaQueryList);

  // function handleMediaQuery(e) {
  //   if (e.matches) {
  //     // min-width: 751px (PC)


  //   } else {
  //     // max-width: 750px (SP)

  //   }
  // }
  // mediaQueryList.addEventListener('change', handleMediaQuery);


  /**
   * スマートフォン表示でブラウザUIを考慮する処理
   * @memo 下記CSSを全画面表示したい要素に設定する
   * @memo min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);
   */
  const setFillHeight = function() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener('resize', setFillHeight);

  // 初期化
  setFillHeight();


  /**
   * アクセシビリティを考慮したスライダー
   * https://ja.splidejs.com/
   */
  /*
  <div class="splide js-slider" aria-label="screen-read-name">
    <div class="splide__track">
      <ul class="splide__list">
        <li class="splide__slide"></li>
      </ul>
    </div>
    <div class="splide__arrows">
      <button class="splide__arrow splide__arrow--prev">Prev</button>
      <ul class="splide__pagination"></ul>
      <button class="splide__arrow splide__arrow--next">Next</button>
    </div>
  </div>
  */
  if ( document.querySelector('.js-slider') !== null ) {
    let jsSlider = new Splide('.js-slider', {
      perPage       : 3,      // 1ページの表示数
      perMove       : 1,      // 1度にスライドを何枚移動するか
      speed         : 800,    // 移動時間（ミリ秒単位）
      type          : 'loop', // ループする場合
      interval      : 4000,   // 自動再生の間隔（ミリ秒単位）
      pauseOnHover  : false,  // マウスホバー時に自動再生をストップさせない
      resetProgress : false,  // 自動再生ストップ時、経過時間を破棄せず続行する
      updateOnMove  : true,   // is-activeクラスを移動前に更新する
      gap           : '30px', // スライド間の余白（cssと同様）
      padding       : '50px', // 左右の見切れる幅（cssと同様）
      easing        : 'cubic-bezier(0.25, 1, 0.5, 1)', // 移動アニメーション
      focus         : 'center',

      // インビュー時から自動再生をスタート（拡張機能：splide-extension-intersection.js）
      autoplay: 'pause',
      intersection: {
        inView: {
          autoplay: true,
        },
        outView: {
          autoplay: false,
        },
        once: true, // 一度画面に表示されたら監視を停止する
      },

      // 動き続けるスライダーを実装（拡張機能：splide-extension-auto-scroll.js）
      autoScroll: {
        speed: 1, // 負の値で流れる向きが右へ変更
        pauseOnHover: false,
        pauseOnFocus: false,
      },

      // メディアクエリ設定（初期：751px以上の場合、スライダーを動作させない）
      // mediaQuery: 'min',
      // breakpoints: {
      //   751: {
      //     destroy: true,
      //   },
      // },
    });

    // スライダーに過不足がある場合にスライダーUIを停止・排除する
    jsSlider.on('overflow', function ( isOverflow ) {
      jsSlider.go(0);
      jsSlider.options = {
        arrows    : isOverflow,
        pagination: isOverflow,
        drag      : isOverflow,
        clones    : isOverflow ? undefined : 0,
      };
    } );

    // スライダーを実行
    jsSlider.mount();
    // jsSlider.mount(window.splide.Extensions);

  }


});

